import React from 'react';
import PropTypes from 'prop-types';
import './alerts.scss';

export default function ErrorMsg({ msg = '', styles }) {
	const handleGoHome = () => {
		window.location.replace('https://cvcodepro.com');
	};

	return (
		<div className={styles.container}>
			<svg
				version='1.0'
				xmlns='http://www.w3.org/2000/svg'
				width='300.000000pt'
				height='130.000000pt'
				viewBox='0 0 300.000000 130.000000'
				preserveAspectRatio='xMidYMid meet'
				className='notfound-icon'
			>
				<metadata>
					Created by potrace 1.10, written by Peter Selinger 2001-2011
				</metadata>
				<g
					transform='translate(0.000000,130.000000) scale(0.100000,-0.100000)'
					fill='#000000'
					stroke='none'
				>
					<path
						d='M1357 1260 c-111 -35 -230 -133 -273 -225 -34 -73 -47 -170 -48 -350
0 -147 2 -184 16 -225 64 -191 105 -266 173 -317 45 -35 195 -117 195 -107 0
4 6 2 13 -4 7 -6 50 -10 102 -9 134 4 186 28 295 136 91 90 103 111 145 256
10 33 20 62 22 65 2 3 23 -17 46 -43 62 -71 116 -88 277 -83 117 4 122 3 73
-8 -65 -16 -81 -29 -89 -72 -10 -54 3 -97 44 -142 67 -75 91 -82 292 -78 188
4 201 5 251 31 48 26 65 52 68 106 3 67 -28 135 -70 153 l-31 13 38 23 c59 35
64 46 64 132 0 69 -3 82 -23 102 -13 13 -42 27 -65 31 l-40 6 0 187 c0 198
-10 275 -41 326 -59 95 -161 126 -256 78 -67 -34 -193 -172 -325 -357 -35 -49
-91 -122 -124 -162 l-61 -71 -7 69 c-13 126 -71 270 -159 400 -43 64 -116 117
-196 142 -72 24 -229 22 -306 -3z m343 -42 c78 -39 110 -71 167 -168 63 -108
68 -121 95 -205 28 -93 31 -263 5 -350 -68 -228 -73 -239 -158 -322 -81 -78
-157 -114 -251 -120 -57 -4 -90 -1 -130 12 -68 22 -175 86 -215 127 -30 32
-93 141 -93 160 0 6 -6 24 -14 41 -42 92 -54 241 -36 427 16 173 31 211 110
290 59 58 98 84 180 118 43 19 62 21 175 19 97 -2 116 -5 165 -29z m1004 -6
c22 -15 49 -45 62 -67 34 -61 48 -198 40 -379 l-7 -151 46 -2 c26 -1 57 -9 71
-17 21 -15 24 -24 24 -83 0 -71 -6 -80 -67 -107 -38 -17 -73 -51 -73 -70 0
-11 8 -13 33 -9 51 8 83 -28 92 -104 9 -77 -2 -96 -62 -119 -40 -16 -78 -19
-233 -19 -178 0 -187 1 -215 23 -50 38 -85 92 -85 129 0 61 11 71 97 78 43 4
84 12 91 18 18 14 45 67 38 74 -3 3 -46 -2 -95 -11 -49 -9 -130 -16 -180 -16
-130 0 -170 18 -243 109 -23 27 -23 84 -1 119 9 15 37 52 63 82 25 30 67 84
93 120 27 36 78 106 114 155 75 101 202 234 248 257 48 25 104 21 149 -10z
m-204 -852 c-19 -13 -30 -13 -30 0 0 6 10 10 23 10 18 0 19 -2 7 -10z'
					/>
					<path
						d='M1425 1191 c-94 -23 -167 -64 -225 -128 -69 -75 -84 -146 -85 -390 0
-133 3 -185 13 -194 6 -7 12 -19 12 -27 0 -7 11 -38 25 -69 26 -55 123 -163
148 -163 7 0 26 -8 42 -17 46 -25 202 -30 248 -8 82 39 227 224 227 291 0 11
4 24 10 30 5 5 14 59 20 119 10 115 -2 321 -20 339 -6 6 -10 16 -10 24 0 8
-18 42 -41 77 -44 68 -83 95 -179 120 -69 18 -97 18 -185 -4z m253 -53 c77
-36 136 -140 149 -258 8 -76 1 -266 -12 -330 -15 -73 -65 -192 -65 -154 0 6 3
14 7 18 4 3 8 12 9 19 2 7 10 31 18 54 19 53 27 95 30 156 3 72 -12 207 -29
256 -8 24 -12 47 -10 51 3 5 1 10 -3 12 -5 1 -24 32 -42 67 -35 65 -100 119
-145 122 -11 0 -29 5 -40 10 -14 6 -3 8 35 4 30 -3 75 -15 98 -27z m-278 8 c0
-2 -23 -18 -50 -36 -83 -53 -147 -134 -177 -225 l-13 -40 5 56 c2 30 8 67 14
81 12 32 81 108 97 108 7 0 14 4 16 8 7 18 108 63 108 48z m145 -21 c51 -7
110 -37 132 -65 27 -35 73 -139 87 -198 21 -83 21 -251 0 -334 -36 -146 -102
-233 -210 -274 -134 -51 -295 61 -355 248 -27 84 -27 279 0 358 23 66 37 93
75 143 49 63 189 155 210 138 6 -5 34 -12 61 -16z m-360 -675 c3 -5 1 -10 -4
-10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m50 -88 c16 -27 52 -66
79 -86 57 -42 48 -49 -11 -9 -50 35 -88 80 -109 129 -21 51 1 33 41 -34z m385
-117 c0 -8 -62 -37 -67 -31 -3 3 10 12 28 20 42 18 39 17 39 11z'
					/>
					<path d='M1472 1056 l-21 -36 23 0 c17 0 26 7 30 26 11 44 -9 49 -32 10z' />
					<path
						d='M1530 1058 c0 -18 18 -34 28 -24 3 4 -2 14 -11 23 -16 16 -17 16 -17
1z'
					/>
					<path d='M1400 1050 c0 -5 9 -14 19 -19 16 -9 18 -8 14 9 -5 20 -33 29 -33 10z' />
					<path
						d='M1370 1026 c0 -9 12 -21 26 -26 32 -12 34 -12 34 0 0 6 -5 10 -12 10
-6 0 -20 7 -30 17 -17 15 -18 15 -18 -1z'
					/>
					<path
						d='M1335 1000 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z'
					/>
					<path
						d='M1370 975 c0 -8 7 -15 15 -15 9 0 12 6 9 15 -4 8 -10 15 -15 15 -5 0
-9 -7 -9 -15z'
					/>
					<path
						d='M1485 970 c-78 -43 -119 -96 -157 -205 -25 -72 -24 -206 3 -298 12
-39 24 -85 26 -100 14 -75 162 -96 243 -34 39 30 87 104 107 165 27 84 26 314
-2 396 -24 70 -45 86 -122 93 -47 3 -66 0 -98 -17z m166 -36 c25 -22 41 -90
47 -200 8 -170 -26 -289 -105 -366 -32 -31 -39 -33 -103 -33 -85 0 -93 7 -122
106 -32 108 -31 283 1 354 41 92 89 140 154 156 41 10 107 1 128 -17z'
					/>
					<path
						d='M1302 964 c1 -9 9 -19 16 -22 9 -3 13 2 10 14 -1 9 -9 19 -16 22 -9
3 -13 -2 -10 -14z'
					/>
					<path
						d='M1340 951 c0 -12 5 -21 10 -21 6 0 10 6 10 14 0 8 -4 18 -10 21 -5 3
-10 -3 -10 -14z'
					/>
					<path
						d='M1274 914 c3 -9 6 -18 6 -20 0 -3 5 -2 10 1 16 10 12 35 -6 35 -11 0
-14 -5 -10 -16z'
					/>
					<path
						d='M1310 900 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z'
					/>
					<path
						d='M1255 877 c-4 -10 -5 -21 -1 -24 10 -10 18 4 13 24 -4 17 -4 17 -12
0z'
					/>
					<path d='M1280 860 c0 -19 11 -26 23 -14 8 8 -3 34 -14 34 -5 0 -9 -9 -9 -20z' />
					<path
						d='M1244 819 c-4 -7 1 -16 10 -21 14 -8 15 -12 5 -24 -10 -12 -14 -12
-20 -2 -4 7 -11 9 -15 5 -12 -11 16 -35 35 -30 11 3 21 17 24 31 8 39 -21 69
-39 41z'
					/>
					<path d='M1220 711 c0 -20 25 -51 41 -51 5 0 1 16 -8 35 -18 39 -33 46 -33 16z' />
					<path d='M1220 630 c0 -13 7 -20 20 -20 23 0 26 10 8 28 -18 18 -28 15 -28 -8z' />
					<path
						d='M1242 550 c0 -28 5 -53 10 -56 17 -10 21 5 7 22 -11 13 -11 18 -1 21
16 6 15 38 -2 52 -11 10 -14 3 -14 -39z'
					/>
					<path d='M1272 438 c5 -15 28 -23 28 -10 0 5 -7 13 -16 16 -10 4 -14 1 -12 -6z' />
					<path d='M1920 671 c0 -20 17 -37 27 -28 7 8 -7 47 -18 47 -5 0 -9 -9 -9 -19z' />
					<path
						d='M1900 621 c0 -6 5 -13 10 -16 8 -5 8 -11 0 -20 -6 -7 -8 -20 -4 -29
3 -9 1 -16 -5 -16 -6 0 -11 -13 -11 -30 0 -20 5 -30 15 -30 11 0 13 8 9 30 -5
23 -3 30 10 30 19 0 21 11 4 28 -10 10 -10 15 1 21 17 11 7 41 -14 41 -8 0
-15 -4 -15 -9z'
					/>
					<path d='M1875 460 c-10 -17 12 -37 25 -24 8 8 6 15 -4 23 -10 9 -16 9 -21 1z' />
					<path
						d='M1860 407 c0 -8 7 -21 16 -28 14 -11 15 -10 12 11 -3 26 -28 41 -28
17z'
					/>
					<path d='M1830 356 c0 -9 7 -16 16 -16 9 0 14 5 12 12 -6 18 -28 21 -28 4z' />
					<path
						d='M1807 324 c-3 -3 3 -15 13 -27 22 -23 40 -15 24 10 -12 19 -27 26
-37 17z'
					/>
					<path
						d='M1790 292 c0 -7 -8 -12 -17 -13 -14 0 -15 -2 -3 -6 8 -3 17 -10 20
-14 3 -4 12 -4 20 1 13 8 13 12 -2 27 -11 11 -18 13 -18 5z'
					/>
					<path
						d='M1750 251 c0 -4 6 -16 14 -27 11 -14 17 -16 26 -7 7 6 10 16 6 21 -7
12 -46 23 -46 13z'
					/>
					<path d='M1730 202 c0 -5 7 -13 16 -16 10 -4 14 -1 12 6 -5 15 -28 23 -28 10z' />
					<path d='M1690 191 c0 -15 28 -33 40 -26 8 5 8 11 -1 21 -13 15 -39 19 -39 5z' />
					<path
						d='M1650 160 c0 -12 35 -31 44 -23 8 9 -14 33 -30 33 -8 0 -14 -4 -14
-10z'
					/>
					<path
						d='M1370 135 c7 -8 19 -15 27 -15 13 0 13 3 3 15 -7 8 -19 15 -27 15
-13 0 -13 -3 -3 -15z'
					/>
					<path d='M1620 135 c14 -16 40 -20 40 -6 0 9 -24 21 -42 21 -6 0 -6 -6 2 -15z' />
					<path d='M1580 132 c0 -16 28 -34 34 -23 4 6 -3 15 -14 21 -11 6 -20 7 -20 2z' />
					<path d='M1510 121 c0 -15 28 -33 46 -29 17 3 17 4 0 21 -18 18 -46 23 -46 8z' />
					<path
						d='M2559 1161 c-60 -38 -103 -87 -241 -270 -132 -176 -145 -192 -188
-241 -61 -68 -73 -89 -57 -96 9 -3 18 -17 21 -31 6 -23 10 -24 49 -18 23 3 60
10 82 16 22 5 112 9 200 7 l160 -3 13 -39 c18 -57 -1 -145 -39 -182 -26 -25
-39 -29 -102 -32 -79 -4 -91 -10 -81 -36 6 -16 17 -18 113 -11 142 9 245 9
306 0 39 -5 54 -4 63 8 23 28 5 42 -48 39 -72 -5 -94 3 -124 46 -22 33 -26 48
-26 114 0 94 7 102 82 90 72 -11 117 -7 137 13 14 14 14 17 1 25 -19 12 -77
13 -84 2 -3 -5 -31 -8 -63 -6 l-58 3 -6 288 c-4 170 -11 299 -18 316 -14 35
-36 34 -92 -2z m65 -26 c6 -17 4 -18 -18 -9 -20 8 -21 24 -2 24 8 0 16 -7 20
-15z m-20 -127 c15 -252 18 -354 8 -379 -10 -25 -15 -26 -134 -37 -305 -30
-353 -10 -242 98 101 98 233 258 280 340 66 113 79 109 88 -22z m-131 10 c-9
-18 -19 -35 -22 -38 -4 -3 -14 -16 -23 -30 -22 -34 -138 -163 -138 -153 1 17
183 253 196 253 1 0 -5 -15 -13 -32z m-313 -413 c0 -8 9 -23 20 -32 29 -25 25
-31 -22 -29 -49 1 -59 19 -31 53 21 26 33 29 33 8z m499 -55 c0 -3 -6 -14 -14
-24 -15 -19 -15 -19 -35 2 -12 12 -26 22 -32 22 -6 0 4 10 23 23 l34 24 12
-21 c7 -12 12 -23 12 -26z m21 -272 c0 -8 -15 -10 -65 -10 -46 0 -47 2 -21 33
14 17 26 36 26 42 1 13 59 -52 60 -65z'
					/>
					<path
						d='M2522 942 c-15 -32 -15 -42 -2 -42 6 0 10 7 10 15 0 8 5 15 10 15 12
0 14 27 2 34 -4 2 -13 -7 -20 -22z'
					/>
					<path
						d='M2480 880 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z'
					/>
					<path
						d='M2539 848 c-15 -28 -102 -142 -137 -180 -14 -15 -24 -29 -21 -31 2
-2 45 -2 94 1 l90 4 -2 110 c-1 61 -4 112 -7 115 -2 3 -10 -6 -17 -19z m-13
-164 c-5 -14 -57 -20 -69 -8 -3 4 10 27 31 52 l37 45 3 -37 c2 -21 1 -44 -2
-52z'
					/>
					<path
						d='M2440 835 c0 -8 -7 -15 -15 -15 -8 0 -15 -7 -15 -15 0 -8 -4 -15 -10
-15 -5 0 -10 -5 -10 -11 0 -10 38 -3 52 10 13 12 20 61 9 61 -6 0 -11 -7 -11
-15z'
					/>
					<path
						d='M2380 750 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z'
					/>
					<path
						d='M2340 722 c0 -5 7 -16 16 -23 14 -12 16 -11 13 5 -4 20 -29 35 -29
18z'
					/>
					<path
						d='M2320 701 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z'
					/>
					<path d='M2300 681 c0 -17 12 -41 20 -41 17 0 17 27 -1 38 -10 7 -19 8 -19 3z' />
					<path
						d='M2262 643 c-6 -3 -10 -9 -6 -14 7 -12 44 -12 44 0 0 11 -21 19 -38
14z'
					/>
					<path d='M2700 1122 c0 -14 29 -42 42 -42 6 0 4 9 -4 22 -16 24 -38 35 -38 20z' />
					<path
						d='M2710 1047 c0 -8 9 -22 20 -32 22 -20 27 -7 10 26 -12 22 -30 25 -30
6z'
					/>
					<path
						d='M2727 984 c-11 -11 -8 -44 3 -44 6 0 10 10 10 23 0 18 2 19 11 7 9
-12 10 -12 7 -1 -5 17 -21 25 -31 15z'
					/>
					<path
						d='M2740 910 c6 -12 9 -28 6 -38 -3 -9 -1 -33 3 -52 8 -30 9 -24 10 38
1 58 -2 72 -14 72 -13 0 -14 -4 -5 -20z'
					/>
					<path d='M2715 901 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z' />
					<path
						d='M2726 771 c-3 -5 3 -20 14 -32 15 -19 19 -21 20 -7 0 37 -21 61 -34
39z'
					/>
					<path d='M2716 652 c-7 -12 22 -38 31 -28 4 3 0 14 -9 22 -9 10 -18 12 -22 6z' />
					<path
						d='M2338 465 c-13 -30 -16 -30 -47 -9 -19 14 -25 14 -32 3 -5 -8 -9 -17
-9 -20 0 -10 61 -17 104 -11 l39 5 -24 18 c-13 10 -18 19 -11 19 14 0 17 20 2
20 -6 0 -16 -11 -22 -25z'
					/>
					<path
						d='M2456 481 c-3 -5 0 -13 7 -18 26 -21 37 -24 37 -11 0 16 -37 41 -44
29z'
					/>
					<path
						d='M2520 465 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z'
					/>
					<path
						d='M2700 470 c0 -13 50 -33 59 -24 7 7 -28 34 -46 34 -7 0 -13 -5 -13
-10z'
					/>
					<path
						d='M2210 455 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z'
					/>
					<path d='M2396 455 c4 -8 18 -15 33 -15 25 1 25 1 7 15 -26 19 -47 19 -40 0z' />
					<path
						d='M2140 450 c0 -5 8 -10 18 -10 10 0 23 -5 30 -12 6 -6 15 -8 19 -4 10
10 -25 36 -49 36 -10 0 -18 -4 -18 -10z'
					/>
					<path
						d='M2780 435 c0 -8 -9 -21 -21 -29 -12 -8 -18 -19 -15 -24 3 -6 1 -12
-6 -14 -6 -2 -8 -10 -4 -16 10 -16 26 -15 26 2 0 8 11 28 25 44 29 35 30 38
10 46 -9 3 -15 0 -15 -9z'
					/>
					<path
						d='M2645 190 c-4 -6 7 -10 25 -10 18 0 29 4 25 10 -3 6 -15 10 -25 10
-10 0 -22 -4 -25 -10z'
					/>
					<path d='M2460 170 c0 -13 28 -25 38 -16 3 4 0 11 -8 16 -20 13 -30 12 -30 0z' />
					<path
						d='M2571 156 c9 -19 22 -26 61 -31 27 -4 52 -5 55 -2 12 13 -10 27 -41
27 -19 0 -39 7 -46 15 -7 8 -19 15 -26 15 -11 0 -11 -5 -3 -24z'
					/>
					<path
						d='M2840 171 c0 -5 7 -12 16 -15 22 -9 24 -8 24 9 0 8 -9 15 -20 15 -11
0 -20 -4 -20 -9z'
					/>
					<path
						d='M2420 164 c0 -3 7 -12 15 -21 20 -19 32 -9 15 12 -12 15 -30 20 -30
9z'
					/>
					<path
						d='M2700 156 c0 -16 21 -26 57 -26 15 0 23 5 20 13 -2 6 -13 11 -25 9
-11 -2 -23 2 -27 8 -9 15 -25 12 -25 -4z'
					/>
					<path
						d='M2515 150 c-3 -5 3 -14 14 -19 26 -15 31 -14 25 3 -7 18 -32 28 -39
16z'
					/>
					<path
						d='M614 1260 c-82 -19 -204 -147 -406 -426 -31 -44 -65 -88 -76 -100
-10 -11 -36 -42 -56 -68 -33 -44 -36 -53 -36 -116 0 -48 4 -71 15 -80 8 -7 15
-16 15 -21 0 -17 42 -51 89 -72 33 -15 74 -22 149 -25 l103 -5 -30 -18 c-19
-12 -34 -32 -42 -58 -10 -35 -9 -45 10 -84 25 -51 61 -91 106 -115 24 -14 59
-17 180 -17 256 0 313 14 351 87 13 25 14 41 5 89 -11 66 -35 106 -68 115 -31
8 -29 16 9 34 47 22 71 61 76 124 3 44 0 62 -17 90 -22 35 -29 39 -88 51 l-33
6 0 177 c0 178 -10 274 -35 323 -22 41 -87 99 -125 109 -39 11 -52 11 -96 0z
m114 -37 c67 -44 88 -84 108 -204 13 -83 14 -131 8 -239 -5 -74 -9 -142 -9
-150 0 -10 14 -15 50 -17 79 -5 95 -21 95 -97 0 -70 -6 -79 -76 -114 -36 -18
-74 -58 -74 -78 0 -3 12 -3 27 0 40 11 81 -11 92 -48 19 -64 22 -89 11 -121
-9 -26 -21 -36 -58 -51 -40 -16 -77 -18 -237 -19 l-190 0 -37 34 c-66 60 -89
135 -56 178 5 6 45 15 89 19 72 6 81 9 99 34 25 35 26 60 3 60 -10 -1 -47 -7
-83 -15 -90 -20 -248 -19 -302 1 -25 9 -61 36 -85 62 -37 40 -43 52 -43 92 0
38 7 54 46 103 25 32 52 66 61 75 8 9 70 91 138 182 122 164 233 283 290 314
40 20 99 20 133 -1z m-193 -863 c-3 -5 -13 -10 -21 -10 -8 0 -14 5 -14 10 0 6
9 10 21 10 11 0 17 -4 14 -10z m-48 -6 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11
1 17 -2 13 -5z'
					/>
					<path
						d='M615 1174 c-55 -28 -185 -172 -284 -314 -13 -19 -54 -72 -90 -118
-36 -45 -82 -103 -102 -128 l-37 -46 22 -34 c19 -31 24 -33 61 -29 22 3 58 10
80 16 22 5 112 9 199 7 l159 -3 14 -40 c12 -34 12 -48 -2 -100 -23 -90 -47
-109 -146 -114 -75 -3 -80 -4 -77 -25 3 -23 15 -23 273 -15 50 2 115 -1 145
-5 46 -7 58 -6 69 8 23 27 -1 40 -69 37 -62 -3 -66 0 -119 64 -18 22 -16 164
3 183 10 10 27 11 81 2 77 -12 128 0 123 29 -4 19 -73 28 -92 11 -9 -7 -22 -7
-37 -1 -14 5 -34 5 -47 0 -16 -6 -24 -5 -27 4 -2 6 -7 143 -12 303 -10 339
-12 347 -88 308z m43 -42 c2 -7 -2 -10 -12 -6 -9 3 -16 11 -16 16 0 13 23 5
28 -10z m-17 -132 c13 -206 13 -369 0 -388 -5 -8 -28 -15 -52 -17 -348 -21
-369 -20 -369 22 0 8 47 65 104 126 129 138 170 190 231 290 66 108 78 104 86
-33z m-125 23 c-20 -38 -136 -181 -177 -218 -29 -26 -24 -8 6 20 5 6 23 30 40
55 24 38 65 90 120 157 19 23 26 13 11 -14z m-316 -427 c5 -13 17 -29 27 -35
10 -6 14 -11 8 -12 -5 0 -23 -3 -38 -5 -16 -3 -35 0 -43 7 -12 10 -11 16 3 40
21 35 31 36 43 5z m479 -77 c-9 -5 -22 -1 -37 13 l-22 22 27 24 28 24 9 -37
c6 -28 5 -39 -5 -46z m20 -214 l20 -35 -37 0 c-20 0 -48 -3 -62 -7 l-25 -6 26
29 c14 16 29 37 32 47 9 23 20 16 46 -28z'
					/>
					<path
						d='M556 938 c-8 -32 -7 -38 4 -38 6 0 10 7 10 15 0 8 5 15 10 15 6 0 10
7 10 15 0 24 -27 18 -34 -7z'
					/>
					<path
						d='M520 880 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z'
					/>
					<path
						d='M575 845 c-28 -43 -81 -113 -127 -167 l-39 -47 93 6 c51 3 94 7 96 8
1 1 1 54 0 116 l-3 114 -20 -30z m-9 -161 c-6 -15 -76 -20 -76 -6 0 4 16 25
35 47 19 22 35 45 37 50 1 6 3 -10 5 -34 3 -24 2 -50 -1 -57z'
					/>
					<path
						d='M470 841 c0 -5 7 -11 15 -15 9 -3 15 0 15 9 0 8 -7 15 -15 15 -8 0
-15 -4 -15 -9z'
					/>
					<path
						d='M450 800 c0 -13 -5 -18 -15 -14 -8 4 -15 1 -15 -5 0 -16 22 -14 48 5
23 16 21 34 -4 34 -8 0 -14 -9 -14 -20z'
					/>
					<path
						d='M410 756 c0 -2 7 -9 15 -16 12 -10 15 -10 15 4 0 9 -7 16 -15 16 -8
0 -15 -2 -15 -4z'
					/>
					<path
						d='M380 716 c0 -10 -6 -13 -17 -9 -16 6 -17 5 -3 -11 8 -11 16 -14 18
-7 2 6 10 11 19 11 17 0 13 22 -5 28 -7 2 -12 -3 -12 -12z'
					/>
					<path
						d='M340 666 c0 -16 5 -26 15 -26 8 0 15 6 15 14 0 7 -7 19 -15 26 -13
11 -15 8 -15 -14z'
					/>
					<path d='M297 644 c-11 -11 3 -24 25 -24 17 0 18 2 8 15 -13 16 -23 19 -33 9z' />
					<path d='M740 1117 c0 -14 20 -37 32 -37 13 0 9 20 -6 36 -18 17 -26 18 -26 1z' />
					<path
						d='M740 1052 c0 -5 11 -19 25 -32 28 -26 33 -16 9 18 -15 22 -34 30 -34
14z'
					/>
					<path
						d='M761 981 c-8 -5 -11 -16 -8 -25 7 -17 27 -22 27 -7 0 5 3 16 6 25 7
18 -4 20 -25 7z'
					/>
					<path
						d='M770 915 c0 -8 -5 -15 -11 -15 -5 0 -7 -5 -4 -10 5 -8 11 -7 21 1 11
9 14 8 14 -3 0 -8 -5 -19 -11 -25 -7 -7 -8 -17 -3 -25 5 -7 7 -21 6 -30 -2
-10 2 -18 7 -18 7 0 11 27 11 70 0 56 -3 70 -15 70 -8 0 -15 -7 -15 -15z'
					/>
					<path
						d='M760 771 c0 -5 9 -19 20 -32 21 -26 27 -11 10 22 -11 19 -30 26 -30
10z'
					/>
					<path d='M750 653 c0 -5 4 -15 10 -23 11 -18 25 -7 17 15 -6 15 -27 21 -27 8z' />
					<path
						d='M382 478 c-7 -7 -12 -18 -12 -25 0 -17 -11 -16 -38 3 -23 16 -42 12
-42 -10 0 -16 40 -23 98 -18 48 5 54 16 15 28 -21 5 -24 8 -10 11 9 3 17 9 17
14 0 13 -14 11 -28 -3z'
					/>
					<path
						d='M496 481 c-3 -5 0 -13 7 -18 26 -21 37 -24 37 -11 0 16 -37 41 -44
29z'
					/>
					<path
						d='M560 471 c0 -12 6 -21 15 -21 8 0 15 4 15 9 0 5 -7 14 -15 21 -13 10
-15 9 -15 -9z'
					/>
					<path
						d='M432 463 c2 -10 14 -19 27 -21 27 -4 21 12 -10 29 -18 9 -20 8 -17
-8z'
					/>
					<path
						d='M740 470 c0 -11 42 -30 63 -30 17 1 -32 40 -49 40 -8 0 -14 -5 -14
-10z'
					/>
					<path d='M247 455 c6 -17 25 -15 31 3 2 7 -5 12 -17 12 -14 0 -19 -5 -14 -15z' />
					<path
						d='M175 450 c-3 -5 2 -10 12 -10 11 0 24 -5 31 -12 17 -17 28 -2 12 17
-14 17 -46 20 -55 5z'
					/>
					<path
						d='M820 435 c0 -8 -9 -21 -21 -29 -12 -8 -18 -19 -14 -25 3 -6 -1 -11
-10 -11 -9 0 -15 -5 -13 -12 8 -24 27 -18 44 14 9 18 23 40 31 49 12 14 12 17
-2 22 -9 4 -15 1 -15 -8z'
					/>
					<path
						d='M685 190 c-4 -6 7 -10 25 -10 18 0 29 4 25 10 -3 6 -15 10 -25 10
-10 0 -22 -4 -25 -10z'
					/>
					<path
						d='M892 183 c-26 -5 -20 -23 9 -23 10 0 19 7 19 15 0 8 -1 14 -2 14 -2
-1 -13 -4 -26 -6z'
					/>
					<path d='M496 165 c4 -8 15 -15 25 -15 23 0 25 16 3 24 -25 10 -34 7 -28 -9z' />
					<path
						d='M600 172 c0 -23 30 -42 78 -48 44 -6 51 -4 47 8 -3 8 -19 15 -36 16
-17 0 -39 8 -48 16 -19 17 -41 21 -41 8z'
					/>
					<path d='M450 162 c0 -10 41 -35 47 -28 9 8 -17 36 -33 36 -8 0 -14 -3 -14 -8z' />
					<path
						d='M730 161 c0 -5 6 -14 14 -20 17 -14 68 -11 64 4 -2 6 -13 9 -25 7
-13 -1 -23 2 -23 8 0 5 -7 10 -15 10 -8 0 -15 -4 -15 -9z'
					/>
					<path d='M556 151 c-4 -5 3 -15 15 -21 18 -10 20 -9 17 6 -4 20 -23 30 -32 15z' />
				</g>
			</svg>
			<div className='error-msg'>
				<div className='error-msg-title'>
					<p>Oops!</p>
				</div>
				<div className='error-msg-content'>
					<p>
						The resource was Not Found. Please, try it again, or{' '}
						<button className='btn-home' onClick={handleGoHome}>
							Go Home
						</button>
					</p>
				</div>
			</div>
		</div>
	);
}

ErrorMsg.propTypes = {
	styles: PropTypes.object.isRequired,
	msg: PropTypes.string,
};
