export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
export const INIT_PROFILE = 'INIT_PROFILE';
export const INIT_SKILLS = 'INIT_SKILLS';
export const INIT_EXPE = 'INIT_EXPE';
export const INIT_EDU = 'INIT_EDU';
export const INIT_PROJ = 'INIT_PROJ';
export const GET_PDF = 'GET_PDF';
export const PDF_DONE = 'PDF_DONE';
export const ERROR_PARAM = 'ERROR_PARAM';
